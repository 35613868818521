import React, {useContext} from 'react';
import PrivacyEn from "./PrivacyEN";
import {AppContext} from "../../context/AppContext";
import PrivacyTR from "./PrivacyTR";

function Privacy(props) {
    const {lang} = useContext(AppContext);

    return (
        <div style={{padding: '10px 20px'}}>
            {
                lang === 'en' ? <PrivacyEn /> : <PrivacyTR />
            }
        </div>
    );
}

export default Privacy;

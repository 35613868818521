import React from 'react';

function PrivacyTr(props) {
    return (
        <div>
            <h1>
                Genel Kullanım Şartları
            </h1>
            <h2>
                1&nbsp;Kullanım Şartları
            </h2>
            <p>
                Bu Kullanım Şartları (bundan sonra ‘<strong>KŞ</strong>’ olarak anılacaktır)
                ‘<strong>Sağlayıcı</strong>’ (aşağıda tanımlanmıştır) ile Web Sitesine ve/veya
                Uygulamasına (aşağıda tanımlanmıştır) erişen ve özelliklerini ve içeriklerini
                herhangi bir şekilde kullanan tüm taraflar arasında geçerli olan ve bu ilişkileri
                yöneten hükümlerdir (bundan sonra ‘<strong>Ziyaretçiler</strong>’ olarak
                anılacaktır).
            </p>
            <p>
                Sağlayıcı ve Ziyaretçiler topluca ‘<strong>Taraflar</strong>’ olarak anılacaktır.
            </p>
            <h2>
                2&nbsp;Genel
            </h2>
            <p>
                Sağlayıcı tarafından Stats365.com web sitesi ve mobil uygulamaları (bundan sonra
                ‘<strong>Uygulama</strong>’) üzerinde sunulan tüm özelliklerin ve hizmetlerin
                kullanımı, aşağıda belirtilen KŞ’lere tabidir. Sağlayıcı tarafından yazılı olarak
                başka bir anlaşma yapılmadığı sürece, KŞ’ler, Sağlayıcı ile Ziyaretçi arasında
                Uygulama üzerindeki her türlü işlevin kullanımı da dahil olmak üzere, tüm ilişkiyi
                oluşturan ve yöneten şartlardır.
            </p>
            <p>
                Ziyaretçi, Uygulama'yı kullanmadan önce KŞ’leri dikkatlice okuma ve anlama
                sorumluluğuna sahiptir. Uygulama'yı görüntüleyen bir Ziyaretçi, KŞ’leri okuduğu,
                anladığı ve bu şartlara bağlı kalmayı kabul ettiği kabul edilir, başka bir işlem
                yapılması gerekmez.
            </p>
            <p>
                Sağlayıcı, gerektiği zaman bu KŞ’leri askıya alma, ekleme, sonlandırma, değiştirme
                ve/veya tamamlamaya hakkını saklı tutar.
            </p>
            <p>
                Sağlayıcı, Ziyaretçiye, bu sayfaların içeriğini düzenli olarak okumasını
                tavsiye eder. Uygulama'yı kullanarak Ziyaretçi, KŞ’lere ve bunlara yapılacak
                en son değişikliklere bağlı kalmayı kabul eder, Ziyaretçi bu değişikliklerden
                haberdar olup olmamış olursa olsun.
            </p>
            <p>
                Sağlayıcı, tüm Ziyaretçilerin KŞ’lerin son güncellenmiş haline göre Uygulama'yı
                kullanıp kullanmadığını doğrulama yükümlülüğü altında değildir. KŞ’nin geçerli
                versiyonu, Uygulama’da yayımlanan sürümdür.
            </p>
            <p>
                Uygulama yalnızca yasal amaçlarla kullanılabilir. Uygulama üzerinden herhangi
                bir materyalin yayılması, dağıtılması, depolanması veya iletilmesi, geçerli
                yasa veya yönetmelikler ya da üçüncü taraf haklarıyla ihlal anlamına gelecek
                bir şekilde yasaktır. Bu, Uygulama'nın veya Uygulama üzerinden iletilen,
                yayımlanan, depolanan materyalin telif hakkı, ticari marka, ticaret sırrı veya
                diğer fikri mülkiyet haklarını ihlal etmesi, müstehcen olması veya reşit olmayan
                kişilere zarar vermesi, yasa dışı bir eylem veya taciz içermesi, iftira veya
                hakaret içermesi, gizlilik veya veri koruma yasalarını ihlal etmesi, dolandırıcılık
                yapması veya herhangi bir döviz kontrolü ya da kumar yasasını ihlal etmesi
                gibi durumları içerir.
            </p>
            <p>
                Uygulamanın kötüye kullanılması ve/veya suistimalleri durumunda Sağlayıcı,
                Ziyaretçiyi Uygulama’dan uzaklaştırma veya Ziyaretçi adına kaydedilen hesabı
                kapatma hakkını saklı tutar. Sağlayıcı ayrıca, Ziyaretçi’ye karşı dava açma
                hakkını da saklı tutar.
            </p>
            <h2>
                3&nbsp;Hizmetler
            </h2>
            <p>
                Uygulama, canlı spor bilgileri, spor sonuçları, son sonuçlar, fikstürler, kadrolar
                ve spor istatistikleri içeren interaktif bir web ve mobil uygulama/özellikler
                sunmaktadır. Uygulama'daki sonuçlar ve diğer istatistik bilgileri, diğer bağımsız
                kaynaklardan (üçüncü taraflardan) veya iç kaynaklardan veya çeşitli diğer resmi
                uygulamalardan sağlanan bilgileri yansıtmaktadır. Sağlayıcı, Uygulama’daki
                içerikleri ve maç sonuçlarını düzenli olarak güncellemek için her türlü çabayı
                göstersede, Ziyaretçiye, Uygulama’daki bilgilerin diğer kaynaklardan da kontrol
                edilmesini tavsiye ederiz. Sağlayıcı, Ziyaretçi'nin Uygulama üzerindeki sonuçlar
                ve diğer bilgilere dayalı kullanımlarından sorumlu değildir.
            </p>
            <h2>
                4&nbsp;Üçüncü Taraf Web Siteleri ve Uygulamalar
            </h2>
            <p>
                Ziyaretçi, Uygulama’yı görüntüledikten sonra, isteyip istemediği bağımsız olarak,
                üçüncü taraflarla yapacağı her türlü iletişimin ve bu iletişimin sonucunun
                tamamen Sağlayıcı’dan bağımsız olduğunu kabul eder. Sağlayıcı, bu iletişim nedeniyle
                doğabilecek herhangi bir anlaşma, beklenti veya diğer sonucu hiçbir şekilde
                sorumlu değildir.
            </p>
            <p>
                Ziyaretçi ile üçüncü taraflar arasında çıkabilecek her türlü dava veya ihtilaf
                Sağlayıcı’yı kesinlikle ilgilendirmez.
            </p>
            <p>
                Üçüncü taraflar, Uygulama üzerinde reklam verenler de dahil olmak üzere, Ziyaretçi’nin
                Kişisel Verilerine veya Ziyaretçi’nin Sağlayıcı’ya verdiği diğer verilere erişim
                hakkına sahip değildir.
            </p>
            <h2>
                5&nbsp;Görsel/İşitsel İçerik
            </h2>
            <p>
                Sağlayıcı, Uygulama’dan görüntülenebilen dış uygulamaların içeriğinden sorumlu
                değildir. Uygulama üzerinde bulunan tüm video içerikler, Sağlayıcı’nın sunucularında
                barındırılmamaktadır ve Sağlayıcı tarafından oluşturulup yüklenmemektedir.
            </p>
            <h2>
                6&nbsp;Aktif Olmayan Hesaplar
            </h2>
            <p>
                Ziyaretçi, Uygulama üzerinde bir hesap oluşturmuş ancak 60 gün boyunca buna erişim
                sağlamamışsa, Sağlayıcı, hesabı derhal ve önceden bildirimde bulunmaksızın kapatma
                hakkını saklı tutar.
            </p>
            <h2>
                7&nbsp;Fikri Mülkiyet
            </h2>
            <p>
                Sağlayıcı’dan yazılı izin alınmaksızın, Ziyaretçiler, Uygulama’nın herhangi bir
                içeriğini kopyalama, değiştirme, bozma, dağıtma, iletme, sergileme, çoğaltma,
                aktarma, yükleme, indirme veya başka bir şekilde kullanma veya değiştirme
                yetkisine sahip değildir.
            </p>
            <p>
                Stats365, Livesport s.r.o.'nun ticari markasıdır. Ticari markanın ve ilgili
                işaretlerin kullanımı yasaktır ve tüm haklar saklıdır.
            </p>
            <p>
                Bu maddeye aykırı bir ihlal, Avrupa Birliği ve diğer geçerli yasalar kapsamında
                fikri mülkiyet haklarının ihlali anlamına gelebilir. Sağlayıcı ve adına yetkilendirilen
                diğer taraflar, bu ihlali doğrudan veya dolaylı olarak gerçekleştiren herhangi bir
                tarafa karşı kanunen izin verilen en yüksek tazminat talebinde bulunma hakkını
                saklı tutar.
            </p>
            <h2>
                8&nbsp;İlişki Türü
            </h2>
            <p>
                Bu KŞ’ler, Sağlayıcı ile Ziyaretçi arasında herhangi bir ortaklık, acentelik veya
                ortak girişim oluşturmayı amaçlamaz.
            </p>
            <h2>
                9&nbsp;KŞ İhlali
            </h2>
            <p>
                Ziyaretçi, KŞ’lerin herhangi bir maddesine uymadığında veya Sağlayıcı, Ziyaretçi’nin
                doğrudan veya dolaylı olarak KŞ’lerin herhangi bir maddesine uymadığını makul bir
                şekilde şüphe ederse, Sağlayıcı, yalnızca kendi takdirine bağlı olarak Ziyaretçi’yi
                Uygulama’dan kapatma veya Ziyaretçi adına kaydedilen hesabı kapatma ve Ziyaretçi’ye
                karşı dava açma hakkını saklı tutar.
            </p>
            <h2>
                10&nbsp;Yasal Uygunluk
            </h2>
            <p>
                Ziyaretçiler, bulundukları veya ikamet ettikleri ve/veya mevcut oldukları
                yargı bölgesinde geçerli yasalarla uyumlu olmaları gerektiği konusunda bilgilendirilir.
                Sağlayıcı, Ziyaretçi'nin Uygulama’yı kullanımı ile ilgili herhangi bir yetkili
                makam tarafından alınan bir önlemden sorumlu değildir.
            </p>
            <h2>
                11&nbsp;Yasa ve Yargı
            </h2>
            <p>
                Bu KŞ’ler, Malta ülkesinin yasalarına tabi olacak şekilde yorumlanacaktır. Taraflar,
                Malta’daki mahkemelerin münhasır yargı yetkisine başvurur.
            </p>
            <h2>
                12&nbsp;Başlıklar
            </h2>
            <p>
                Başlıklar, bu KŞ’lerin okunmasını kolaylaştırmak amacıyla yerleştirilmiştir.
                Başlıklar, her başlığın ardından gelen paragrafın içeriğini yorumlama amacı taşımamaktadır.
                Başlıklar, Sağlayıcı’yı hiçbir şekilde bağlamaz.
            </p>
            <h2>
                13&nbsp;Feragatname
            </h2>
            <p>
                Sağlayıcı tarafından, herhangi bir Ziyaretçinin bu ToU'nun herhangi bir maddesini ihlal etmesi durumunda herhangi bir feragat yapılması, aynı ya da diğer maddelerin ihlali durumunda yapılacak herhangi bir feragat olarak kabul edilmez.
            </p>
            <h2>
                14&nbsp;Feragat
            </h2>
            <h2>
                14.1&nbsp;Garantiler ve Beyanlar
            </h2>
            <p>
                Burada, Sağlayıcı'nın, Uygulama içeriğinin doğru ve/veya herhangi bir özel amaç için uygun olduğu konusunda herhangi bir beyan, taahhüt veya garanti (açık veya dolaylı) yapmadığı belirtilmektedir, ancak bu ToU'nun geçerli yasalarına göre açıkça hariç tutulamayan garantiler dışında.
            </p>
            <p>
                Uygulamanın kullanımı tamamen Ziyaretçinin riskine bağlıdır. Uygulama bir oyun ya da kumar uygulaması değildir. Uygulamanın Sağlayıcısı, oyun ya da kumar hizmeti sağlamaz; bu nedenle oyuncu fonlarını tutmaz veya kontrol etmez ve herhangi bir oyun işlemine dahil değildir. Uygulama üzerinde görüntülenen bahis oranları, Uygulamanın bilgi ve işlevlerinin bir parçasıdır.
            </p>
            <p>
                Sağlayıcı, şunları garanti etmez: - Uygulama tarafından sağlanan herhangi bir işlevin yetkilendirildiği, işlemin Ziyaretçiyi tamamen tatmin edeceği, tamamen güvenli olduğu ve hatadan muaf olduğu, düzenli olarak güncellendiği, herhangi bir yazılım hatasının düzenli olarak düzeltildiği, kesintisiz olduğu, virüs veya hata içermediği, sürekli olarak çalıştığı, yeterli olduğu, mevcut bilgilerin ve işlevlerin güvenilir olduğu veya Uygulama üzerinde elde edilen tüm diğer bilgilerin ve kullanılan işlevlerin yeterli ve güvenilir olduğu. Siteyi erişmeye karar verenler, kendi inisiyatifleriyle bu kararı verir ve yerel yasalarla uyumlu olmaktan sorumludurlar, yerel yasalar geçerli olduğu ölçüde.
            </p>
            <p>
                Uygulama, üçüncü taraf web sitelerine/uygulamalara/reklamlara/içeriğe bağlantılar ve referanslar içerebilir. Diğer web siteleri/uygulamalar/reklamlar/içerikler, genellikle o web sitelerinde/uygulamalarda/reklamlarda/içeriklerde bulunan ayrı şartlar ve koşullara tabidir. Bunlar, Ziyaretçinin kolaylığı ve ilgisi için sağlanmıştır ve Sağlayıcı'nın bu web sitelerindeki/uygulamalardaki/reklamlardaki/içeriklerde bulunan bilgilerle ilgili sorumluluk taşıdığı veya onayladığı anlamına gelmez. Sağlayıcı, kendi alanı dışında olan içeriklerin veya bilgilerin doğruluğu, erişilebilirliği, metin veya grafikler konusunda herhangi bir garanti vermez. Sağlayıcı, diğer web sitelerinde/uygulamalarda bulunan yazılımları test etmemiştir ve bu tür yazılımların kalitesi, güvenliği, güvenilirliği veya uygunluğu hakkında herhangi bir beyan yapmaz.
            </p>
            <h2>
                14.2&nbsp;Zarar veya Kayıp
            </h2>
            <p>
                Sağlayıcı, Uygulamayı kullanma sonucu Ziyaretçi veya üçüncü şahısların uğradığı doğrudan veya dolaylı herhangi bir kayıp veya zarardan sorumlu değildir. Bu zararlar arasında ticari kayıplar, kazanç kayıpları, beklenen gelirler, kazançlar veya diğer karlar, iş kesintileri, ticari bilgiler kayıpları veya diğer mali ve/veya ardışık kayıplar dahil ancak bunlarla sınırlı olmamak kaydıyla zararlar bulunmaktadır.
            </p>
            <p>
                Sağlayıcı, üçüncü taraf web siteleri/uygulamaları üzerinde yapılan kazançlar veya kayıplardan sorumlu değildir, bunlar Uygulama üzerindeki bilgilerin kullanılması sonucu meydana gelir.
            </p>
            <p>
                Önceki iki maddeye sınırlama getirmeksizin, burada sorumluluğun kabul edilmediği ve üstlenilmediği bazı durumlar şunlardır:
            </p>
            <ol>
                <li>
                    hata(lar), yazım hatası(ları), yanlış anlama(lar), yanlış işitme(ler), yanlış okuma(lar), yanlış çeviri(ler), yazım hatası(ları), okuma hatası(ları), işlem hatası(ları), teknik tehlike(ler), kayıt hatası(ları), belirgin hata(lar), mücbir sebep ve/veya diğer benzer hata(lar)/hata(lar);
                </li>
                <li>
                    Sağlayıcı’nın kurallarının ihlali;
                </li>
                <li>
                    suçlu eylemler;
                </li>
                <li>
                    Sağlayıcı tarafından sağlanan herhangi bir tavsiye;
                </li>
                <li>
                    yasal eylemler ve/veya diğer çözüm yolları;
                </li>
                <li>
                    Ziyaretçilerin veya üçüncü şahısların, Uygulamanın içeriğini veya Sağlayıcı tarafından önerilen herhangi bir bağlantıyı kullanmalarından kaynaklanan kayıplar veya zararlar;
                </li>
                <li>
                    Ziyaretçilerin veya üçüncü şahısların, Uygulamanın herhangi bir değişikliği, askıya alınması veya kesintiye uğraması nedeniyle uğradığı kayıplar veya zararlar;
                </li>
                <li>
                    Uygulamanın veya içeriğinin herhangi bir suçlu tarafından kullanımı, bir kusur veya eksiklik veya Sağlayıcı’nın kontrolü dışındaki herhangi bir faktör;
                </li>
                <li>
                    Ziyaretçi’nin Kullanıcı Adı ve Parolasını kullanarak özel alanlara üçüncü şahısların erişmesi sonucu Uygulamanın herhangi bir şekilde kullanılması;
                </li>
                <li>
                    Uygulamada bulunan virüsler veya hatalar nedeniyle Uygulamanın sunduğu hizmetlerde, işlevlerde ve diğer tüm özelliklerde meydana gelen tutarsızlıklar nedeniyle herhangi bir zarar, maliyet, masraf, kayıp veya dava;
                </li>
                <li>
                    İnternet sağlayıcısı veya Ziyaretçilerin Uygulamaya erişmek için sözleşme yaptıkları herhangi bir üçüncü şahsın herhangi bir eylemi veya ihmali. İnternet sağlayıcısı ile Ziyaretçi arasında herhangi bir anlaşmazlık durumunda, Sağlayıcı davaya taraf olamaz ve bu dava, bu ToU’yu hiçbir şekilde etkilemez;
                </li>
                <li>
                    Sağlayıcı tarafından Uygulama üzerinde yetkilendirilmemiş başka bir Ziyaretçi veya üçüncü şahıs tarafından yapılan herhangi bir malzemenin içeriğinden kaynaklanan zararlar veya zararlar.
                </li>
            </ol>
            <h2>
                15&nbsp;Gizlilik Politikası
            </h2>
            <p>
                Stats365.com web sitesi ve mobil uygulamaları (bundan sonra ‘Uygulama’ olarak anılacaktır), Uygulamayı görüntüleyen ve kullanan tüm tarafların gizliliğine saygı duyar. Uygulama, Ziyaretçilerine sağlanan hizmetleri sağlamak amacıyla ve yalnızca aşağıda açıkça belirtilen amaçlar doğrultusunda, Ziyaretçilerine ait ‘Kişisel Veriler’i (aşağıda tanımlanmıştır) toplayabilir ve kullanabilir.
            </p>
            <p>
                Bu Gizlilik Politikası, uluslararası Sözleşmelere ve AB yasalarına, 2016/679 Sayılı Genel Veri Koruma Yönetmeliği'ne (GDPR) ve Malta yasalarına (Malta Yasası 440, Kişisel Verilerin İşlenmesi) uygun olarak hazırlanmıştır ve ayrıca diğer geçerli kurallar ve uygulamalarla uyumludur.
            </p>
            <p>
                Kişisel verilerin toplanması ve kullanılması ile ilgili olarak denetleyici, Livesport s.r.o., Bucharova 2928/14a, 158 00 Prag, Çek Cumhuriyeti olup, bu Uygulamadan sorumludur.
            </p>
            <p>
                Veri Denetleyicisi ile şu e-posta adresinden iletişime geçilebilir: support@fu.... .
            </p>
            <p>
                Sağlayıcı ile şu e-posta adresinden iletişime geçilebilir: support@fu.... .
            </p>
            <h2>
                16&nbsp;Bu Beyanın Amacı
            </h2>
            <p>
                Bu beyanın amacı:
            </p>
            <ul>
                <li>
                    Denetleyicinin sizden hangi tür kişisel verileri toplayacağını ve kişisel bilgilerinizi nasıl kullanacağımızı belirlemek;
                </li>
                <li>
                    Kişisel verilerin işlenme temellerini açıklamak;
                </li>
                <li>
                    Denetleyicinin kişisel verilerinizi nasıl işleyeceğini size bildirmek;
                </li>
                <li>
                    Denetleyicinin, kişisel verilerinizi yasal ve sorumlu bir şekilde işlemedeki yükümlülüklerini açıklığa kavuşturmak; ve
                </li>
                <li>
                    Veri koruma haklarınızı size bildirmek.
                </li>
            </ul>
            <p>
                Kişisel verilerinizi uygun ve yasal bir şekilde, geçerli veri koruma yönetmelikleri ve 25 Mayıs 2018'den itibaren yürürlükte olan Genel Veri Koruma Yönetmeliği EU 2016/679 ("<strong>GDPR</strong>")'ye uygun olarak işleriz.
            </p>
            <h2>
                17&nbsp;Kişisel Verilerin Toplanması
            </h2>
            <p>
                Bu Gizlilik Politikasında özellikle belirtilmediği sürece, Ziyaretçiler yalnızca Uygulama’yı gezindiğinde Kişisel Veri toplamaz. Ancak, Uygulama, Ziyaretçilerden kayıt sonrasında Uygulama’da sunulan ek veya gelişmiş hizmetleri kullanırken bazı Kişisel Veriler sağlamalarını talep edebilir. Bu durumlarda, Uygulama, Ziyaretçilerden Kişisel Verilerinin toplanması ve kullanılması öncesinde onaylarını isteyecektir. Kayıt sırasında veya diğer zamanlarda Uygulama, Ziyaretçilere giriş adı (genellikle bir e-posta adresi) ve şifre sağlamalarını isteyebilir.
            </p>
            <p>
                Ziyaretçiler, Kişisel Verilerini sağlamaya veya Uygulama’nın Kişisel Verilerini toplamasına izin verme konusunda herhangi bir zorunluluk altında değildir. Ancak, Kişisel Verilerinin toplanmasına izin vermeyen Ziyaretçilere, Uygulama’nın sunduğu tüm hizmetler sağlanamayabilir.
            </p>
            <p>
                Ziyaretçiler, Uygulama’nın Kişisel Verilerini kullanmasını onaylamış olsalar da, istedikleri herhangi bir nedenle, destek@fu.... adresinden iletişime geçerek onaylarını geri alabilirler. Onayın geri alınması durumunda, kayıt sonrasında sağlanan ek veya gelişmiş hizmetler artık kullanılamaz olacaktır.
            </p>
            <h2>
                18&nbsp;Veri Sahibi Olarak Haklarınız
            </h2>
            <p>
                18.1.1&nbsp;Erişim Hakkı
            </p>
            <p>
                Ziyaretçiler, Denetleyici’den (aşağıda tanımlanmıştır) kendilerinin hangi Kişisel Verilerini topladığı ve/veya kullandığına dair yazılı bilgi talep etme hakkına sahiptir. Talep, Denetleyici’ye yazılı olarak yapılabilir.
            </p>
            <p>
                Uygulama, toplanan Kişisel Verilerin güncel tutulmasını sağlamak için makul çabalarını gösterecektir. Bununla birlikte, Ziyaretçiler, Uygulama’ya sahip oldukları Kişisel Verilerdeki değişiklikleri bildirmeye davet edilir.
            </p>
            <p>
                18.1.2&nbsp;Veri Düzeltme, Engelleme veya Silme Hakkı
            </p>
            <p>
                Ziyaretçiler, Kişisel Verilerinin yanlış olduğunu düşünüyorsa, yazılı olarak Denetleyici’den bu verilerin düzeltilmesini talep edebilirler. Ziyaretçiler ayrıca, Kişisel Verilerinin hukuka aykırı bir şekilde işlenmesi durumunda, Denetleyici’den verilerinin engellenmesini veya silinmesini talep etme hakkına sahiptir.
            </p>
            <p>
                18.1.3&nbsp;İtiraz Hakkı
            </p>
            <p>
                Bize her zaman destek@fu.... adresinden ulaşarak, Kişisel Verilerinizi pazarlama amaçlarıyla işlemememizi talep edebilirsiniz. Bu durumda verileriniz pazarlama amaçlarıyla işlenmeyecektir.
            </p>
            <p>
                18.1.4&nbsp;Onay Geri Alma Hakkı
            </p>
            <p>
                Bu beyanı ve burada açıklanan işleme uygulamalarını istediğiniz zaman geri alabilirsiniz. Geri alma işlemi, bu onay temelinde gerçekleştirilen işleme işlemlerinin hukuki geçerliliğini etkilemez. Onayın geri alınması, hizmetlerimizi derhal sonlandırmamıza neden olacaktır.
            </p>
            <p>
                18.1.5&nbsp;Düzeltme Hakkı
            </p>
            <p>
                Kişisel Verilerinizdeki yanlış bilgilerin düzeltilmesini, güncel olmayan verilerin güncellenmesini ve eksik Kişisel Verilerin tamamlanmasını sağlama hakkınız vardır. Bu, tamamlayıcı bir açıklama ile yapılabilir.
            </p>
            <p>
                18.1.6&nbsp;Silme Hakkı
            </p>
            <p>
                Kişisel Verilerinizin silinmesini talep etme hakkınız vardır, aşağıdaki durumlarda kişisel verilerinizin silinmesi gerekmektedir:
            </p>
            <ul>
                <li>
                    Kişisel Verilerinizi işlememiz için verdiğiniz onayı geri almanız;
                </li>
                <li>
                    Kişisel Verilerinizin işlenmesine artık gerek kalmaması; veya
                </li>
                <li>
                    Kişisel Verilerinizin hukuka aykırı işlenmesi.
                </li>
            </ul>
            <p>
                18.1.7&nbsp;İşleme Kısıtlaması Hakkı
            </p>
            <p>
                Aşağıdaki durumlarda işleme faaliyetlerimizin kısıtlanmasını talep etme hakkınız vardır:
            </p>
            <ul>
                <li>
                    Kişisel Verilerinizin doğruluğuna itiraz etmeniz ve bu doğruluğu doğrulamamız için makul bir süre vermeniz;
                </li>
                <li>
                    İşlemenin hukuka aykırı olduğunu düşündüğünüzde, kişisel verilerinizin silinmesini değil, kullanımının kısıtlanmasını talep etmeniz;
                </li>
                <li>
                    Kişisel Verilerinizi belirli amaçlar doğrultusunda daha fazla işlememize gerek kalmaması, ancak bu verilerinizi hukuki haklarınızı kurmak, kullanmak veya savunmak için ihtiyaç duymanız;
                </li>
                <li>
                    İşlemeye itiraz etmeniz, bu itirazın doğruluğunu kontrol etmemizi beklemeniz.
                </li>
            </ul>
            <p>
                18.1.8&nbsp;Veri Taşınabilirliği Hakkı
            </p>
            <p>
                25 Mayıs 2018 itibariyle, Kişisel Verilerinizi yapılandırılmış ve makine tarafından okunabilir bir formatta alma ve bu veriyi başka bir Denetleyici’ye (GDPR’de tanımlandığı şekilde) iletme hakkınız vardır.
            </p>
            <h2>
                19&nbsp;Kişisel Verilerin Toplanma ve Kullanılma Amaçları
            </h2>
            <p>
                Uygulama tarafından toplanan Kişisel Veriler, Malta Yasaları (Malta Hukuku Bölümü 440) ve buna ilişkin yasal düzenlemelere uygun olarak işlenecek ve yalnızca aşağıdaki amaçlarla işlenecektir:
            </p>
            <ol>
                <li>
                    Ziyaretçiler ile iletişim kurmak;
                </li>
                <li>
                    Ziyaretçilere kişisel hesaplarına yeni şifre göndermek;
                </li>
                <li>
                    Uygulama’yı bir dereceye kadar yapılandırarak sunulabilecek gelişmiş hizmetler sağlamak;
                </li>
                <li>
                    Uygulama tarafından sunulan içerikleri geliştirmek;
                </li>
                <li>
                    Ziyaretçilere kişiselleştirilmiş Uygulama içeriği ve/veya düzeni sağlamak.
                </li>
            </ol>
            <h2>
                20&nbsp;İşleme Hukuki Dayanağı
            </h2>
            <p>
                Kişisel Verilerinizi yalnızca onay verdiğinizde veya sunduğumuz hizmetleri sağlayabilmemiz ve/veya bu beyanda belirtilen amaçlarla işlenmesi gerektiğinde işleyeceğiz.
            </p>
            <p>
                Ayrıca, Kişisel Verilerinizi, meşru menfaatlerimiz doğrultusunda veya yasal yükümlülüklerimize uymak için de işleyebiliriz. Bu, hukuki iddiaların savunulması veya herhangi bir mahkeme, yargı organı veya yetkili makamın kararına uymak amacıyla olabilir.
            </p>
            <h2>
                21&nbsp;Pazarlama
            </h2>
            <p>
                Bizden pazarlama iletişimi almayı talep ettiyseniz ve bu iletişimi almak için gerekli bilgileri sağladıysanız, pazarlama iletişimi alırsınız.
            </p>
            <p>
                Kişisel Verilerinizi, açık onayınız olmadan herhangi bir üçüncü şahısla pazarlama amaçlarıyla paylaşmayacağız.
            </p>
            <h2>
                22&nbsp;Kişisel Verilerin Üçüncü Taraflarla Paylaşılması
            </h2>
            <p>
                Sağlayıcı, Ziyaretçilerin Kişisel Verilerini, önceden açık onayları olmadan herhangi bir üçüncü şahısla satmaz, takas yapmaz, kiralamaz veya başka bir şekilde açıklamaz. Ancak, Kişisel Veriler, Uygulama'nın satılması durumunda üçüncü şahıslara açıklanabilir.
            </p>
            <p>
                Yukarıda belirtilen durum, Uygulama'nın Ziyaretçilerin Kişisel Verilerini üçüncü taraflara açıklamaya yönelik herhangi bir yasal yükümlülüğünü engellemez.
            </p>
            <p>
                Yukarıda belirtilen durum, Kişisel Verilerin toplanması ve kullanılması amaçları doğrultusunda kesinlikle gerekli olan açıklamaları da engellemez. Bu durumda, Uygulama, ilgili Ziyaretçilerin açık onayını almak için önceden izin isteyecektir.
            </p>
            <p>
                Sağlayıcı, Ziyaretçiler, satışlar, trafik ve Uygulama ile ilgili diğer istatistiksel bilgileri üçüncü şahıslara sağlama hakkını saklı tutar, ancak herhangi bir Ziyaretçiyi tanımlamadan bu bilgileri sunacaktır.
            </p>
            <h2>
                23&nbsp;Kayıt Dosyaları
            </h2>
            <p>
                Uygulamayı daha iyi yönetmek ve Ziyaretçilerin genel demografik bilgilerini toplamak amacıyla, Uygulama otomatik olarak tüm Ziyaretçilerin IP adreslerini ve her Ziyaretçinin sırasıyla görüntülediği sayfaları kaydeder.
            </p>
            <h2>
                24&nbsp;Çerez Uyarısı
            </h2>
            <p>
                "Çerez", bir Ziyaretçinin bilgisayarında bir web sunucusu tarafından depolanan ve web hizmetlerini özelleştirmek için kullanılan bilgidir. Uygulama, Ziyaretçilerin etkileşimleri hakkında, sonraki bir işlevi yerine getirebilmek için gerekli olabilecek bilgileri depolamak amacıyla çerezler kullanır. Ziyaretçiler, tarayıcı ayarlarında çerezleri devre dışı bırakmayı seçebilirler. Çerezleri, içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafik analizlerimizi yapmak için kullanıyoruz. Ayrıca, sitenizi kullanımınız hakkında bilgilerimizi Uygulama analiz ortaklarımız, bazı reklam ortaklarımız (programatik) ve sosyal medya (yalnızca sosyal hesabınızla giriş yaptığınızda) ile paylaşıyoruz. Detaylar için <a href="">buraya</a> tıklayabilirsiniz.
            </p>
            <p>
                Bu açıklama, Çerez Politikamız ile birlikte okunmalıdır ve ayrıca Kişisel Verileriniz hakkında toplama veya işleme yaptığımız belirli durumlarda sağladığımız herhangi bir diğer <strong>Gizlilik Bildirimi</strong> ile birlikte okunmalıdır. Böylece Kişisel Verilerinizi nasıl ve neden kullandığımıza dair tam bir farkındalığa sahip olursunuz.
            </p>
            <h2>
                25&nbsp;Kişisel Verilerin Üçüncü Ülkelere Transferi
            </h2>
            <p>
                Denetleyici, Kişisel Verileri Avrupa Ekonomik Alanı dışına aktarmamaktadır ve bu gerekli olursa, Kişisel Verilerinizin yeterince korunmasını sağlamak için uygun önlemler alacaktır.
            </p>
            <h2>
                26&nbsp;Güvenlik
            </h2>
            <p>
                Uygulama ve Denetleyici, toplanan ve kullanılan Kişisel Verilerin imha, kayıp, kötüye kullanım ve değişikliklere karşı korunmasına yardımcı olmak için teknik ve örgütsel çeşitli önlemler almıştır (veri transferlerinin güvenliğini sağlamak dahil). Ziyaretçilerin cihazları ile Sağlayıcı sunucuları arasında güvenli iletişim sağlanarak “HTTPS” kullanılmaktadır. Ayrıca, oluşturduğunuz tüm şifreler BCrypt standardı ile saklanmaktadır. Bu çabalara rağmen, Sağlayıcı, böyle bir olayın meydana gelmeyeceğini garanti edemez.
            </p>
            <h2>
                27&nbsp;Kişisel Verilerin Saklanma Süresi
            </h2>
            <p>
                Kişisel Veriler, toplandığı amaçları yerine getirmek için gereken süre boyunca saklanacaktır. Kullanıcı hesabı bir (1) yıl süreyle etkin olmayan durumdaysa, tüm toplanan kullanıcı Kişisel Verileri silinecektir.
            </p>
            <h2>
                28&nbsp;Üçüncü Taraf Web Siteleri/Uygulamalar
            </h2>
            <p>
                Üçüncü taraf web siteleri/uygulamaları bu Gizlilik Politikasına dahil edilmemektedir. Uygulama, Ziyaretçilere kolaylık sağlamak amacıyla diğer sitelere bağlantılar sunar. Uygulama, üçüncü taraf web siteleri/uygulamalarında görüntülenen içeriklerden ve bu sitelerin/uygulamaların gizliliğinizi korumak için aldıkları önlemlerden sorumlu değildir. Uygulamadan erişilen diğer web siteleri/uygulamalar, yeterli bir Gizlilik Politikası'na sahip olmayabilir.
            </p>
            <h2>
                29&nbsp;Gizlilik Politikasının Kabulü
            </h2>
            <p>
                Ziyaretçilerin, Kişisel Verilerinin Uygulama ve Denetleyici tarafından toplanması ve kullanılması konusunda verdikleri onay, yukarıda belirtilen Gizlilik Politikası'na tabidir. Ziyaretçilere, Gizlilik Politikasını düzenli olarak incelemeleri, içeriğindeki hüküm ve maddeleri tanımaları ve zaman zaman Uygulama tarafından yapılan değişikliklerle ilgili bilgi sahibi olmaları tavsiye edilir.
            </p>
            <h2>
                30&nbsp;Sağlayıcı
            </h2>
            <p>
                Sağlayıcı, 22 Kasım 2011 tarihinde Malta yasalarına göre yasal olarak kayıtlı olan Livesport Media Ltd. adlı bir şirkettir. Kayıt numarası C 54555 olup, kayıtlı adresi CMS House, First Floor, St. Peter's Street, San Gwann, SGN 2310, Malta’dır. Bizimle iletişime geçmek için, lütfen support@fu.... adresine e-posta gönderin.
            </p>
            <h2>
                31&nbsp;Sürüm ve ToU Tarihi
            </h2>
            <p>
                Bu beyan, sürüm 1 olarak 25.5.2018 tarihinde güncellenmiştir (FS.COM-ToU-20180525-1).
            </p>
        </div>
    );
}

export default PrivacyTr;

import React, {useContext} from 'react';
import './BasePages.css'
import App from "../../App";
import {AppContext} from "../../context/AppContext";

function Terms(props) {
    const {i18String} = useContext(AppContext);

    return (
        <div className="const_width terms-section">
            <div className="content_container">
                <h1 className="heading" dangerouslySetInnerHTML={{ __html: i18String("Subscription Terms and Conditions") }} />
                <p className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("This Terms and Conditions enables you to understand Premium better.") }} />
                <h3 className="title-center" dangerouslySetInnerHTML={{ __html: i18String("Cancellation") }} />
                <p className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("You can cancel your Premium at any time. No further payments will occur once you've canceled.") }} />
                <p className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("All Premium features are retained until the end of your paid subscription.") }} />
                <p className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("unsubscribe") }} />
                <h4 className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("Friendly Upgrade Terms") }} />

                <ul>
                    <li>{i18String('Your account will be upgraded to Premium immediately upon payment.')}</li>
                    <li>{i18String('You will receive new future Premium features as long as you are subscribed to Premium.')}</li>
                    <li>{i18String('You will be automatically charged monthly for Credit Card, Debit Card, or PayPal payments.')}</li>
                </ul>

                <h4 className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("Refund Policy") }} />
                <p className="content-text title-center" dangerouslySetInnerHTML={{ __html: i18String("We accept refund requests within 3 days of your first purchase. Please contact us from the contact form at the footer.Renewal payments are non-refundable. You must cancel before your renewal.") }} />

                <p className="heading">{i18String('Terms of Use')}</p>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">1</strong>
                        {i18String('terms1t')}
                    </p>
                    <p className="content-text">{i18String('terms1')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">2</strong>
                        {i18String('terms2t')}
                    </p>
                    <p className="content-text">{i18String('terms2')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">3</strong>
                        {i18String('terms3t')}
                    </p>
                    <p className="content-text">{i18String('terms3')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">4</strong>
                        {i18String('terms4t')}
                    </p>
                    <p className="content-text">{i18String('terms4')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">5</strong>
                        {i18String('terms5t')}
                    </p>
                    <p className="content-text">{i18String('terms5')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">6</strong>
                        {i18String('terms6t')}
                    </p>
                    <p className="content-text">{i18String('terms6')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">7</strong>
                        {i18String('terms7t')}
                    </p>
                    <p className="content-text">{i18String('terms7')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">8</strong>
                        {i18String('terms8t')}
                    </p>
                    <p className="content-text">{i18String('terms8')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">9</strong>
                        {i18String('terms9t')}
                    </p>
                    <p className="content-text">{i18String('terms9')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">10</strong>
                        {i18String('terms10t')}
                    </p>
                    <p className="content-text">{i18String('terms10')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">11</strong>
                        {i18String('terms11t')}
                    </p>
                    <p className="content-text">{i18String('terms11')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">12</strong>
                        {i18String('terms12t')}
                    </p>
                    <p className="content-text">{i18String('terms12')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">13</strong>
                        {i18String('terms13t')}
                    </p>
                    <p className="sub-heading">
                        13.1 {i18String('terms13.1t')}
                    </p>
                    <p className="content-text">{i18String('terms13.1')}</p>
                    <p className="sub-heading">
                        13.2 {i18String('terms13.2t')}
                    </p>
                    <p className="content-text">{i18String('terms13.2')}</p>
                    <ol>
                        <li>{i18String('terms13.2.1')}</li>
                        <li>{i18String('terms13.2.2')}</li>
                        <li>{i18String('terms13.2.3')}</li>
                        <li>{i18String('terms13.2.4')}</li>
                        <li>{i18String('terms13.2.5')}</li>
                        <li>{i18String('terms13.2.6')}</li>
                        <li>{i18String('terms13.2.7')}</li>
                        <li>{i18String('terms13.2.8')}</li>
                        <li>{i18String('terms13.2.9')}</li>
                        <li>{i18String('terms13.2.10')}</li>
                        <li>{i18String('terms13.2.11')}</li>
                    </ol>

                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">14</strong>
                        {i18String('terms14t')}
                    </p>
                    <p className="content-text">{i18String('terms14')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">15</strong>
                        {i18String('terms15')}
                    </p>
                    <p className="content-text">
                        {i18String('terms15')}
                    </p>
                    <ul>
                        <li>{i18String('terms15.1')}</li>
                        <li>{i18String('terms15.2')}</li>
                        <li>{i18String('terms15.3')}</li>
                        <li>{i18String('terms15.4')}</li>
                        <li>{i18String('terms15.5')}</li>
                    </ul>

                    <p className="content-text">{i18String('terms15.6')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">16</strong>
                        {i18String('terms16t')}
                    </p>
                    <p className="content-text">{i18String('terms16')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">17</strong>
                        {i18String('terms17t')}
                    </p>
                    <p className="sub-heading">
                        17.1 {i18String('terms17.1t')}
                    </p>
                    <p className="content-text">{i18String('terms17.1')}</p>
                    <p className="sub-heading">
                        17.2 {i18String('terms17.2t')}
                    </p>
                    <p className="content-text">{i18String('terms17.2')}</p>
                    <p className="sub-heading">
                        17.3 {i18String('terms17.3t')}
                    </p>
                    <p className="content-text">{i18String('terms17.3')}</p>
                    <p className="sub-heading">
                        17.4 {i18String('terms17.4t')}
                    </p>
                    <p className="content-text">{i18String('terms17.4')}</p>
                    <p className="sub-heading">
                        17.5 {i18String('terms17.5t')}
                    </p>
                    <p className="content-text">{i18String('terms17.5')}</p>
                    <p className="sub-heading">
                        17.6 {i18String('terms17.6t')}
                    </p>
                    <p className="content-text">{i18String('terms17.6')}</p>
                    <ul>
                        <li>
                            {i18String('terms17.6.1')}
                        </li>
                        <li>
                            {i18String('terms17.6.2')}
                        </li>
                        <li>
                            {i18String('terms17.6.3')}
                        </li>
                    </ul>
                    <p className="sub-heading">
                        17.7 {i18String('terms17.7t')}
                    </p>
                    <p className="content-text">
                        {i18String('terms17.7')}
                    </p>
                    <ol>
                        <li>{i18String('terms17.7.1')}</li>
                        <li>{i18String('terms17.7.2')}</li>
                        <li>{i18String('terms17.7.3')}</li>
                        <li>{i18String('terms17.7.4')}</li>
                    </ol>

                    <p className="sub-heading">
                        17.8 {i18String('terms17.8t')}
                    </p>
                    <p className="content-text">{i18String('terms17.8')}</p>

                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">18</strong>
                        {i18String('terms18t')}
                    </p>
                    <ol>
                        <li>{i18String('terms18.1')}</li>
                        <li>{i18String('terms18.2')}</li>
                        <li>{i18String('terms18.3')}</li>
                        <li>{i18String('terms18.4')}</li>
                        <li>{i18String('terms18.5')}</li>
                    </ol>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">19</strong>
                        {i18String('terms19t')}
                    </p>
                    <p className="content-text">{i18String('terms19')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">20</strong>
                        {i18String('terms20t')}
                    </p>
                    <p className="content-text">{i18String('terms20')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">21</strong>
                        {i18String('terms21t')}
                    </p>
                    <p className="content-text">{i18String('terms21')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">22</strong>
                        {i18String('terms22t')}
                    </p>
                    <p className="content-text">{i18String('terms22')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">23</strong>
                        {i18String('terms23t')}
                    </p>
                    <p className="content-text">{i18String('terms23')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">24</strong>
                        {i18String('terms24t')}
                    </p>
                    <p className="content-text">{i18String('terms24')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">25</strong>
                        {i18String('terms25t')}
                    </p>
                    <p className="content-text">{i18String('terms25')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">26</strong>
                        {i18String('terms26t')}
                    </p>
                    <p className="content-text">{i18String('terms26')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">27</strong>
                        {i18String('terms27t')}
                    </p>
                    <p className="content-text">{i18String('terms27')}</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">28</strong>
                        {i18String('terms28t')}
                    </p>
                    <p className="content-text">{i18String('terms28')}</p>
                </div>
            </div>
        </div>
);
}

export default Terms;
